import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import useSystemsData from '../queries/useSystemsData';
import {colors, devices} from '../styles/theme';
import {NavigationContext} from '../services/navigation';
import {GridComponentContainer, Subtitle} from '../components/atoms';
import {SystemsFilter} from '../components/molecules';
import {Header, SystemPreview, SEO} from '../components/organisms';
import {PageContent} from '../components/organisms/Layout';
import {ContactUs} from '../components/organisms/blocks';

const ThumbnailsContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const ShowFilter = styled(Subtitle)`
  display: block;
  grid-column: 1 / -1;
  padding-left: 16px;
  font-size: 16px;
  color: ${colors.lightBlue};
  cursor: pointer;
  @media ${devices.tabletPortrait} {
    display: none;
  }
`;

const transferTypeMapping = {
  people: ['people', 'peopleCargo', 'peopleLuggage'],
  cargo: ['cargo', 'peopleCargo'],
  peopleCargo: ['peopleCargo'],
  peopleLuggage: ['peopleLuggage']
};

const Systems = () => {

  const {setLocation} = React.useContext(NavigationContext);
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  const data = useSystemsData();

  const [sectors, setSectors] = useState([]);
  const [transferType, setTransferType] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const noFilters = sectors.length === 0 && !transferType;
  const filteredSystemsIds = noFilters
    ? data.systems.map(s => s.name)
    : data.systems
    .filter(s => sectors.length === 0 || s.sectors.some(({id}) => sectors.includes(id)))
    .filter(s => !transferType || transferTypeMapping[transferType].includes(s.transferType))
    .map(s => s.name);

  const {header, contact} = data.systemOverview || {};
  const sortedSystems = React.useMemo(() => (
    [...data.systems].sort((a, b) => (
      a.displayOrder - b.displayOrder
    ))
  ), [data.systems]);

  return (
    <PageContent>
      <SEO
        title={data.systemOverview?.SEO?.title}
        description={data.systemOverview?.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: data.systemOverview?.SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      {header && <Header header={header}/>}
      <ShowFilter onClick={() => setShowFilters(!showFilters)}>
        {`${showFilters ? 'Hide' : 'Show'} filters`}
      </ShowFilter>
      <SystemsFilter
        sectorOptions={data.sectors}
        sectors={sectors}
        setSectors={setSectors}
        transferType={transferType}
        setTransferType={setTransferType}
        hidden={!showFilters}
      />
      <ThumbnailsContainer fromColumn={2} widthInColumns={6}>
        {sortedSystems.map(system => (
          <SystemPreview
            key={system.name}
            system={system}
            disabled={!filteredSystemsIds.includes(system.name)}
          />
        ))}
      </ThumbnailsContainer>
      {contact && <ContactUs {...contact}/>}
    </PageContent>
  );
};

export default Systems;
