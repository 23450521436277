import {graphql, useStaticQuery} from 'gatsby';

const useSystemsData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      systemOverview {
        SEO {
          ...SEO
        }
        header {
          ...RegularHeader
        }
        contact {
          ...ContactUs
        }
      }
      systems {
        ...SystemBasicInfo
      }
      sectors {
        id
        name
        icon {
          ...MediaFile
        }
      }
    }
  }
`);

  return ({
    ...data.strapi,
  });
};
export default useSystemsData;
