import React from 'react';
import styled from 'styled-components';

import {devices} from '../../styles/theme';
import {GridComponentContainer, Radio} from '../atoms';

const Container = styled(GridComponentContainer)`
  display: ${({show}) => show ? 'grid' : 'none'};
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  padding: 0 20px;
  @media ${devices.tabletPortrait} {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FilterRadio = styled(Radio)`
  @media ${devices.tabletPortrait} {
    &:nth-child(4n+1) {
      padding-left: 20px;
    }
  }
  @media ${devices.tabletLandscape} {
    &:nth-child(4n+1) {
      padding-left: 0;
    }
  }
`;

const StoriesFilter = ({name, options, checked, setChecked, show}) =>
  <Container fromColumn={2} widthInColumns={6} show={show}>
    {options.map(option =>
      <FilterRadio
        key={option}
        name={name}
        checked={option === checked}
        onChange={() => setChecked(option)}
      >
        {option}
      </FilterRadio>
    )}
  </Container>;

export default StoriesFilter;
