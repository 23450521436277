import React from 'react';
import styled, {css} from 'styled-components';

import {colors, devices} from '../../styles/theme';
import CargoIcon from '../../images/svgsComponents/Cargo.svg';
import PeopleIcon from '../../images/svgsComponents/People.svg';
import PeopleCargoIcon from '../../images/svgsComponents/PeopleCargo.svg';
import {GridComponentContainer, Checkbox, Subtitle} from '../atoms';
import Img from 'gatsby-image';

const Container = styled(GridComponentContainer)`
  display: ${({hidden}) => hidden ? 'none' : 'grid'};
  background: ${colors.lightGrey};
  padding: 24px 20px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.desktop} {
    padding: 24px 0;
  }
`;

const Question = styled(Subtitle)`
  font-weight: bold;
  color: ${colors.blue};
  margin-bottom: 16px;
`;

const Column = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  @media ${devices.desktop} {
    &:first-child {
      padding-left: 48px;
    }
  }
`;

const FilterCheckbox = styled(Checkbox)`
  margin-bottom: 24px;
`;

const logoImageStyle = css`
  width: 32px;
  height: 32px;
`;

const Logo = styled.div`
  margin: 0 16px 0 8px;
  display: flex;
  align-items: center;
  svg {
    ${logoImageStyle}
  }
`;

const LogoImage = styled(Img)`${logoImageStyle}`;
const NativeLogoImage = styled.img`
  ${logoImageStyle}
  margin: 0;
`;

const transferOptions = [
  {
    // matches CMS transfer type field
    id: 'people',
    label: 'People',
    icon: <PeopleIcon />,
  },
  {
    // matches CMS transfer type field
    id: 'cargo',
    label: 'Cargo',
    icon: <CargoIcon />,
  },
  {
    // matches CMS transfer type field
    id: 'peopleCargo',
    label: 'People & Cargo',
    icon: <PeopleCargoIcon />,
  },
  // {
  //   // matches CMS transfer type field
  //   id: 'peopleLuggage',
  //   label: 'People & Luggage',
  //   icon: <PeopleIcon />,
  // },
];

const SystemsFilter = ({
  sectorOptions,
  sectors,
  setSectors,
  transferType,
  setTransferType,
  hidden
}) => (
  <Container fromColumn={3} widthInColumns={4} hidden={hidden}>
    <Column>
      <Question>1. In what sector do you operate?</Question>
      {sectorOptions.map(({id, name, icon}) =>
        <FilterCheckbox
          key={id}
          checked={sectors.includes(id)}
          onChange={() => sectors.includes(id)
            ? setSectors(sectors.filter(x => x !== id))
            : setSectors([id, ...sectors])
          }
        >
          <Logo>
            {icon?.file?.extension === 'svg'
              ? <NativeLogoImage
                src={icon?.file?.publicURL}
                alt={icon?.alternativeText}
              />
              : <LogoImage
                fluid={icon?.file?.childImageSharp?.fluid}
                alt={icon?.alternativeText}
              />
            }
          </Logo>
          {name}
        </FilterCheckbox>
      )}
    </Column>
    <Column>
      <Question>2. What do you want to transfer?</Question>
      {transferOptions.map(({id, label, icon}) =>
        <FilterCheckbox
          key={id}
          checked={transferType === id}
          onChange={() => setTransferType(id)}
        >
          <Logo>
            {icon}
          </Logo>
          {label}
        </FilterCheckbox>
      )}
    </Column>
  </Container>
);

export default SystemsFilter;
